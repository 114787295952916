import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import Cache from './Cache'
import CartManager from './CartManager'
import PromiseActuator from './PromiseActuator'
import ScrollEventListener from './ScrollEventListener'
import { getEntranceGuide, getCartNum } from './apis'
import { getStepsByApiData } from './format'
import { useCartNum } from './hooks/useCartNum'

import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'
import type { CartTagTipConfig, CacheKeys, TagTip, CartInfo, CartType, CartListener, CartHorizontalLurePointInfo } from '../types'

// import mock from './mock/freeToSave'

export * from './hooks/useCartNum'
export type * from '../types'

class CartTagTip {
  appConfigs: Libs.AppConfigs
  initPromiseActuator = new PromiseActuator()
  preRequestPromiseActuator?: PromiseActuator
  scrollEventListener?: ScrollEventListener
  config: CartTagTipConfig = {
    autoUseCoupon: true,
    maxTip: 3,
    requestType: 1,
    disabledTagTip: false,
    disabledFirstTimeTip: false,
    disabledScrollEventListener: false,
    // getCountryId
  }
  cache?: Record<CacheKeys, Cache>
  cartHorizontalLurePointInfo?: CartHorizontalLurePointInfo

  cartManager: CartManager

  constructor() {
    this.appConfigs = useAppConfigs()

    this.cartManager = new CartManager({
      success: () => {
        if (!this.cache) return
        this.cache.tipTotal.set(this.cache.tipTotal.get() + 1)
      },
    })
  }

  async initialize(
    config: {
      getCountryId?: () => string
      autoUseCoupon?: boolean
      needUpdateCartNum?: boolean
      disabledTagTip?: boolean
      disabledFirstTimeTip?: boolean
      disabledScrollEventListener?: boolean
      disabledCache?: boolean
      refresh?: boolean
    } = {},
  ) {
    if (typeof window === 'undefined') return

    const _config = Object.assign(
      {
        needUpdateCartNum: true,
        refresh: false,
      },
      config,
    )

    const task: Promise<any>[] = []

    if (_config.needUpdateCartNum) task.push(this.updateCartNum())
    if (!_config.disabledScrollEventListener && !this.scrollEventListener) {
      this.scrollEventListener = new ScrollEventListener(bool => {
        if (!bool) return
        this.triggerEndtime()
      })
    }

    if (typeof _config.disabledTagTip === 'boolean') this.config.disabledTagTip = _config.disabledTagTip
    if (typeof _config.disabledFirstTimeTip === 'boolean') this.config.disabledFirstTimeTip = _config.disabledFirstTimeTip
    if (typeof _config.getCountryId === 'function') this.config.getCountryId = _config.getCountryId
    if (typeof _config.autoUseCoupon === 'boolean') this.config.autoUseCoupon = _config.autoUseCoupon

    if (this.config.disabledTagTip === true) {
      await Promise.all(task)
      this.initPromiseActuator.resolve()
      return
    }

    this.cache = {
      lure: new Cache('lure', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 当前利诱信息
      apiData: new Cache('apiDataCache', { clear: _config.refresh, disabledSession: _config.disabledCache }), // api 要求的缓存数据
      exposeData: new Cache('exposeData', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 埋点数据
      onceTip: new Cache('onceTip', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 是否首次访问时展示过利诱信息
      tipTotal: new Cache('tipTotal', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 当前回话气泡展示的次数
      excludeBubbles: new Cache('excludeBubbles', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 缓存展示过的气泡
      totalPrice: new Cache('totalPrice', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 购物车总到手价
      cartList: new Cache('cartList', { clear: _config.refresh, disabledSession: _config.disabledCache }), // 购物车数据
    }

    const lureCache = this.cache.lure.get()

    if (!lureCache || _config.refresh) {
      task.push(this.#getApi({ requestType: 1 }))
    }

    await Promise.all(task)
    this.initPromiseActuator.resolve()
  }

  async refresh(
    config: {
      getCountryId?: () => string
      autoUseCoupon?: boolean
      needUpdateCartNum?: boolean
    } = {},
  ) {
    await this.initPromiseActuator.promise

    if (this.config.disabledTagTip) return

    await this.initialize({ ...config, refresh: true })
    await this.initPromiseActuator.promise

    // 更新利诱
    this.triggerShowtime({ noTips: true })
    this.triggerShowtimeByBff({ noTips: true })
  }

  /**
   * 利诱数据预取
   * 当调用 preRequest 后, 再调用 showtime 时, showtime 不再调用 getApi,
   * 而是拿 preRequest 预取的数据
   */
  async preRequest(params: { lureScene?: 'home' | 'list' | 'list_search', excludeUniqueType?: string } = {}) {
    await this.initPromiseActuator.promise

    if (this.config.disabledTagTip) return

    return this.#preRequest(params)
  }

  async #preRequest(params: { lureScene?: 'home' | 'list' | 'list_search', excludeUniqueType?: string } = {}) {
    // 没有 Promise, 创建一个新的 Promise
    if (!this.preRequestPromiseActuator) {
      this.preRequestPromiseActuator = new PromiseActuator()

      const res = await this.#getApi({
        requestType: 2, // 主动调用
        ...params,
      })

      this.preRequestPromiseActuator?.resolve(res)
    }

    return this.preRequestPromiseActuator.promise
  }

  async showtime(
    config: {
      excludeScroll?: boolean
      callback?: () => void
      needUpdateCartNum?: boolean
      cartInfo?: CartInfo
      lureScene?: 'home' | 'list' | 'list_search'
      excludeUniqueType?: string
    } = {},
  ) {
    if (config.needUpdateCartNum) this.updateCartNum(config.cartInfo)

    await this.initPromiseActuator.promise

    if (this.config.disabledTagTip) return

    let res

    if (this.preRequestPromiseActuator) {
      res = await this.preRequestPromiseActuator.promise
      this.preRequestPromiseActuator = undefined
    } else {
      res = await this.#getApi({
        requestType: 2, // 主动调用
        lureScene: config.lureScene,
        excludeUniqueType: config.excludeUniqueType,
      })
    }

    if (!res) {
      config.callback?.()
      return '/order/cart/get_entrance_guide 接口异常'
    }

    this.triggerShowtime({ callback: config.callback })
    this.triggerShowtimeByBff(config)

    // 页面中存在购物车是，才会执行 showtime
    const bubble = res.appStyleControl.bubble
    if (bubble) {
      this.cache?.excludeBubbles.set([
        ...new Set([...(this.cache.excludeBubbles.get() || []), bubble.type]),
      ])
    }
  }

  async update(
    config: {
      noTips?: boolean
      excludeScroll?: boolean
      callback?: () => void
      needUpdateCartNum?: boolean
      cartInfo?: CartInfo
      excludeUniqueType?: string
    } = {},
  ) {
    // 执行 update 时，清除没有被消费的 preRequest
    this.preRequestPromiseActuator = undefined

    const _config = Object.assign(
      {
        needUpdateCartNum: true,
      },
      config,
    )

    // update 的调用只会在更新购物车数据时
    if (_config.needUpdateCartNum) this.updateCartNum(_config.cartInfo)

    await this.initPromiseActuator.promise

    if (this.config.disabledTagTip) return

    const res = await this.#getApi({ requestType: 1, excludeUniqueType: config.excludeUniqueType })

    if (!res) {
      return '/order/cart/get_entrance_guide 接口异常'
    }

    this.triggerShowtime({
      noTips: _config.noTips,
      excludeScroll: _config.excludeScroll,
      callback: _config.callback,
    })
    this.triggerShowtimeByBff({
      noTips: _config.noTips,
      excludeScroll: _config.excludeScroll,
      callback: _config.callback,
    })
  }

  // 首次提示
  async firstTimeTip() {
    await this.initPromiseActuator.promise

    if (this.config.disabledTagTip) return
    if (this.config.disabledFirstTimeTip) return

    if (this.cache) {
      if (this.cache.onceTip.get()) return
      this.cache.onceTip.set(true)
      setTimeout(() => {
        this.triggerShowtime()
        this.triggerShowtimeByBff()
      }, 700)
    }
  }

  async updateCartNum(cartInfo?: CartInfo) {
    if (cartInfo) {
      useCartNum().update({ num: cartInfo.cartSumQuantity })
      return
    }
    const res: any = await getCartNum()
    if (res.code == 0) {
      useCartNum().update({ num: res.info.cartSumQuantity as number })
    }
  }

  async updateCartList() {
    try {
      const res: any = await getEntranceGuide({
        requestType: 2,
        auto_use_coupon: +this.config.autoUseCoupon,
        country_id: this.config.getCountryId?.() || '',
        excludeBubbles: '',
        lurePointCacheInfo: '',
      })
      if (res.code == 0) {
        const itemList = res.info.cartMetaInfo?.item_list || []
        if (this.cache) {
          this.cache.cartList.set(itemList)
        }
        return itemList
      }
      return []
    } catch (e) {
      return []
    }
  }

  async getCurrentTag(): Promise<TagTip.Step> {
    await this.initPromiseActuator.promise

    let steps: TagTip.Step[] | undefined = undefined
    if (!this.config.disabledTagTip && this.cache) {
      steps = getStepsByApiData(this.cache.lure.get(), this.cache.totalPrice.get())
    }
    const step = steps?.pop()
    if (step) return step

    // @ts-ignore
    return {
      type: '',
      realType: '',
      tag: false,
      category: null,
      tips: '',
      noTips: true,
      excludeScroll: false,
    }
  }

  async getLure(): Promise<any> {
    await this.initPromiseActuator.promise
    let lure
    if (!this.config.disabledTagTip && this.cache) {
      lure = this.cache.lure.get()
    }
    return lure
  }

  async getExposeData() {
    await this.initPromiseActuator.promise

    let exposeData: any = {}
    if (!this.config.disabledTagTip && this.cache) exposeData = this.cache.exposeData.get() || {}

    const res: any = {}
    Object.keys(exposeData).forEach(key => {
      res[key] = exposeData[key] || '-'
    })

    return {
      ...res,
      actual_point: exposeData.labelActualPoint || '-', // 实际可展示的标签类型
      available_point: exposeData.labelAvailablePoint || '-', // 可展示标签类型
      actual_point_tip: exposeData.bubbleActualPoint || '-', // 实际可展示的气泡类型
      available_point_tip: exposeData.bubbleAvailablePoint || '-', // 可展示气泡类型
    }
  }

  isOverMaxTip() {
    if (this.cache) {
      return this.cache.tipTotal.get() >= this.config.maxTip
    }
    return false
  }

  triggerShowtime(config?: { noTips?: boolean; excludeScroll?: boolean; callback?: () => void }) {
    if (!this.cache) return config?.callback?.()
    let steps = getStepsByApiData(this.cache.lure.get(), this.cache.totalPrice.get())
    const noTips = !!config?.noTips || this.isOverMaxTip()
    if (noTips) {
      const lastStep = steps.pop()
      if (lastStep) {
        steps = [
          {
            ...lastStep,
            noTips: true,
          },
        ]
      }
    }

    if (steps.length) {
      steps[0].excludeScroll = !!config?.excludeScroll
    }

    this.cartManager.triggerShowtime(steps, {
      callback: config?.callback,
    })

    return steps
  }

  triggerShowtimeByBff(config?: { noTips?: boolean; excludeScroll?: boolean; callback?: () => void }) {
    const noTips = !!config?.noTips || this.isOverMaxTip()
    this.cartManager.triggerShowtimeByBff({
      cartHorizontalLurePointInfo: this.cartHorizontalLurePointInfo,
      appStyleControl: this.cache?.lure.get(),
      totalPrice: this.cache?.totalPrice.get(),
      lurePointBiData: this.cache?.exposeData.get(),
    }, { ...config, noTips })
  }

  triggerQuickCartsEndtime(config) {
    this.cartManager.triggerQuickCartsEndtime(config)
  }

  triggerEndtime() {
    this.cartManager.triggerEndtime()
  }

  async #getApi(config: { requestType: number, lureScene?: 'home' | 'list' | 'list_search', excludeUniqueType?: string }) {
    let result: any = null
    try {
      const countryId = this.config.getCountryId?.() || ''
      const autoUseCoupon = this.config.autoUseCoupon
      const requestType = config.requestType // 被动调用传 1 主动调用传 2
      const lureScene = config.lureScene // 调用场景
      const lurePointCacheInfo = this.cache?.apiData.get()
      const excludeBubbles = 'mark_down,oos,flash_sale,coupon,order_return_coupon'
      const excludeUniqueType = config.excludeUniqueType

      const _data: any = {
        country_id: countryId,
        auto_use_coupon: +autoUseCoupon,
        lureScene,
        requestType,
        lurePointCacheInfo,
        excludeBubbles,
        excludeUniqueType,
      }

      if (this.appConfigs.$envs.project === 'pc') {
        _data.callScene = 'pc'
      }
      // 主动调用
      if (config.requestType === 2) {
        _data.requestType = 2
        _data.excludeBubbles = this.cache?.excludeBubbles.get()?.join(',') || ''
        _data.lurePointCacheInfo = '' // 不对比缓存数据
      }

      const res: any = await getEntranceGuide(_data)
      if (res.code == 0) {
        result = res.info.lurePointInfo

        // TODO mock start

        // const mockdata = mock.shift()
        // result = mockdata.lurePointInfo
        // res.info.cartMetaInfo.item_list = mockdata.cartMetaInfo.item_list

        // TODO mock end

        this.config.maxTip = result.appStyleControl.config.bubbleShowNum

        // cache
        if (this.cache) {
          this.cache.totalPrice.set(res.info.cartMetaInfo.totalPrice)
          this.cache.cartList.set(res.info.cartMetaInfo.item_list)
          this.cache.lure.set(result.appStyleControl)
          this.cache.apiData.set(result.lurePointCacheInfo)
          this.cache.exposeData.set(result.lurePointBiData)
        }
        this.cartHorizontalLurePointInfo = res.info.cartHorizontalLurePointInfo
      }
    } catch (e) {
      // cache
      if (this.cache) {
        this.cache.cartList.set(null)
        this.cache.lure.set(null)
        this.cache.apiData.set(null)
        this.cache.totalPrice.set(null)
        this.cache.exposeData.set(null)
      }
    }

    return result
  }
}

let once = true

export const registerCart = (type: CartType, listener: CartListener, config?: { only?: boolean }) => {
  if (typeof window === 'undefined') return
  cartTagTip.cartManager.register(type, listener, config)

  if (once) {
    once = false
    cartTagTip.firstTimeTip()
  }
}

export const unregisterCart = (type: CartType, listener: CartListener) => {
  if (typeof window === 'undefined') return
  cartTagTip.cartManager.unregister(type, listener)
}

export const cartTagTip: CartTagTip =
  typeof window !== 'undefined'
    ? // @ts-ignore
      window.__cartTagTip__ || (window.__cartTagTip__ = new CartTagTip())
    : {
        updateCartNum: () => {},
      }
